import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { FaMicrosoft } from "react-icons/fa";

const ExternalRange = () => (
  <React.Fragment>
    <Helmet>
      <title>Outdoor Blinds | Melbourne | Brisbane | Birk</title>
      <meta
        property="og:title"
        content="Outdoor Blinds | Melbourne | Brisbane | Birk"
      />
      <meta
        name="keywords"
        content="Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds"
      />
      <meta
        name="description"
        content="Birk offers range of quality Outdoor Blinds. We are operated in Melbourne and Brisbane"
      />
      <meta
        property="og:description"
        content="Birk offers range of quality Outdoor Blinds. We are operated in Melbourne and Brisbane"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="http://birk.itechnotion.com/external-range"
      />
      <link rel="canonical" href="http://birk.itechnotion.com/external-range" />
    </Helmet>
    <Header />
    <section className="product-section">
      <div className="container-fluid all-section">
        <div className="row">
          <div className="col-md-4 col-lg-3">
            <div className="heading">
              <h2>External range</h2>
            </div>
            <div className="product-menu">
              <ul>
                <li className="mb-2">
                  <Link to="/roller-blinds">Roller Blinds</Link>
                </li>
                <li className="mb-2">
                  <Link to="/roman-blinds">Roman Blinds</Link>
                </li>
                <li className="mb-2">
                  <Link to="/curtains">Curtains</Link>
                </li>
                <li className="mb-2">
                  <Link to="/shutters">Shutters</Link>
                </li>
                <li className="mb-2">
                  <Link to="/venetians">Venetians</Link>
                </li>
                <li className="mb-2">
                  <Link to="/external-range">
                    <span className="font-weight-bold">External Range</span>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/automation">Automation</Link>
                </li>
                <li className="mb-2">
                  <Link to="/honeycomb">Honeycomb</Link>
                </li>
              </ul>
              <div className="links-list sidebar-links">
                <Link to="/gallery" className="links-list-item">
                  <FaMicrosoft />
                  &nbsp; Go to gallery
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-lg-9 product-item mt-2">
            <div className="col-12 col-md-12 mb-4">
              <div className="pro-start-content">
                <div className="product-inner">
                  <p className="text-justify">
                    Birk offers a wide range of external sun control systems to
                    complement every external façade. Our Outdoor Blinds /
                    External Blinds and External Screens are designed to take
                    the toughest of treatments by reducing the sun’s penetration
                    and absorbing the heat. Designed to control the entry of
                    natural light, heat and sun radiation from the outside,
                    Outdoor Blinds and External Screens allow you to extend
                    outdoor living areas while maximising the advantages of
                    glass and view from the inside. Unaffected by extreme
                    temperature changes, Outdoor Blinds and External Screens
                    operate using discreet stainless steel side cables or sleek
                    aluminium zip guide side channels, powder coated to suit any
                    exterior. Birk’s external fabrics come in a wide range of
                    colourways and are anti-static for easy cleaning. External
                    screens can be manually operated or motorised and come with
                    quality and performance warranties.
                  </p>
                  <h4 className="font-weight-bold">OUTDOOR BLIND FEATURES</h4>
                  <div className="why-list why-list2 ml-4">
                    <ul>
                      <li>Reduces sun's heat</li>
                      <li>Reduces air conditioning costs</li>
                      <li>Crank or motorised operations</li>
                      <li>
                        Stainless steel guide cables or zip side guide channels
                      </li>
                      <li>Headboxes to suit architecture</li>
                      <li>Powder coat colours to suit</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-5 col-sm-12 mt-2 mb-4">
              <div className="d-flex flex-wrap">
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/range-icon1.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">
                    Crank
                    <br />
                    Handle
                  </p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/range-icon2.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">Motorisation</p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/range-icon3.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">
                    Folding Arm
                    <br />
                    Awning
                  </p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/range-icon4.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">Straight Drop</p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/range-icon5.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">
                    Auto Fold
                    <br />& Pivot
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/brange1.png"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/brange2.png"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/brange3.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </React.Fragment>
);
export default ExternalRange;
